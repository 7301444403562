.add-prompt-container {
  display: flex;
  flex-direction: column;
}
.add-prompt-container > form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
}
.add-prompt-container > form > label {
  margin-block: 8px;
  box-sizing: border-box;
}

.add-prompt-container > form > input[type='text'],
.add-prompt-container > form > textarea {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 1.3rem;
  width: 100%;
  box-shadow: 0 1px 2px var(--shadow-primary);
  transition: box-shadow 0.2s ease;
  background: var(--bg-secondary);
  margin-bottom: 16px;
  font-family: inherit;
  color: var(--font-secondary);
}

.add-prompt-container > form > textarea {
  resize: none;
  min-height: 30vmin;
}

.add-prompt-container > input:focus,
.add-prompt-container > textarea:focus {
  outline: none;
  box-shadow: 0 0 4px var(--shadow-primary);
}

.add-prompt-container > form > button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: var(--accent-green);
  color: var(--font-accent-green);
  cursor: pointer;
  box-shadow: 0 1px 2px var(--shadow-primary);
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}
