.timeline-container {
  color: var(--font-secondary);
}

.timeline-container fieldset {
  border-radius: 10px;
  /* border: 1px solid violet; */
  border: none;
  margin-block-end: 25px;
  /* box-shadow: 0px 1px 6px var(--shadow-primary); */
  background: var(--bg-secondary);
  cursor: pointer;
}

.journal-card pre {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  word-spacing: 3px;
  font-size: 18px;
  white-space: break-spaces;
  word-break: break-word;
}
.journal-card legend {
  background: var(--bg-secondary);
  border-radius: 25px;
  padding-inline: 20px;
  box-shadow: 0px 0px 4px var(--shadow-primary);
}

.journal-month-header {
  color: var(--font-primary);
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: x;
}
