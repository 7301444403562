.home-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding-block: 3rem 1rem;
  padding-inline: 50px;
  background: var(--color-primary);
  min-height: 100vh;
  transition: all 0.5s;
}

@media (max-width: 950px) {
  .home-container {
    padding-right: 10px;
  }
}
@media (max-width: 600px) {
  .home-container {
    padding-inline: 10px;
  }
}
