body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/****** font color ****/

.App {
  /* --bg-dark: #2962b4;
  --bg-light: #145dbe; */
  /* --color-primary: linear-gradient(0deg, rgba(246, 178, 242, 1) 0%, rgba(251, 217, 249, 1) 100%);
  --color-primary-light: linear-gradient(0deg, #f9ccf7 0%, rgba(254, 242, 253, 1) 100%);
  --color-bg-light: #fce5fb;
  --color-bg-dark: #f8bff5;
  --color-neutral-light: #8993a4;
  --font-family-1: 'Merriweather Sans', sans-serif;
  --font-family-2: 'kalam'; */

  /* primary: 60% */
  /* secondary: 30% */
  /* accent: 10% */
  height: 100%;
  --bg-primary: #eceaff;
  --bg-secondary: hsl(260, 43%, 99%);
  --bg-accent: #111;
  --font-primary: #333333;
  --font-secondary: #333333;
  --shadow-primary: #08071066;
  --shadow-primary-dark: #666666;
  --accent-red: #ff426b;
  --accent-green: #6bdc6b;
  --font-accent-red: #dfdfdf;
  --font-accent-green: #2f2f2f;
  --bg-blur: rgba(255, 255, 255, 0.6);
  background: var(--bg-primary);
}

/********************* Themes *************************/
.light-blue {
  --bg-primary: #a362b4;
  --bg-secondary: #fdedff;
  --bg-accent: #fafafa;
  --bg-accent: #ccffcc;
  --bg-accent-secondary: #333;

  --accent-red: #ff0000;
  --accent-green: #6bdc6b;

  --font-primary: #fafafa;
  --font-secondary: #4c2a85;

  --font-accent-red: #202020;
  --font-accent-green: #2f2f2f;
  --shadow-primary: #bfbfbf;
}
.beige {
  /* --bg-primary: #e3e096; */
  --bg-primary: #f7f6de;
  --bg-primary: linear-gradient(360deg, hsla(60, 55%, 66%, 1) 0%, hsla(158, 77%, 77%, 1) 100%);

  --bg-secondary: #fff5ec;
  --bg-third: #adad60;

  --bg-accent: #121212;
  --bg-accent-secondary: #254e58cc;

  --accent-red: red;
  --accent-green: #00ef00;

  --font-primary: #333333;
  --font-secondary: #3a3a3f;
  --font-primary-dark: #222;

  --font-accent-red: #00d878;
  --font-accent-green: #d80078;

  --shadow-primary: #0000004d;
  --shadow-primary-dark: #666;
}
.dusty-blue {
  --bg-primary: #221226;
  --bg-secondary: #562c60;
  /* --bg-secondary: #603e56; */
  /* --bg-secondary: #2e6060; */
  --bg-accent: #0fa3a9;
  --bg-accent-secondary: #c2c2f0bc;

  --accent-red: #990000;
  --accent-green: #009900;

  --font-primary: #d5d5f5bb;
  --font-secondary: #dedede;
  --font-secondary: #c2c2c2bc;

  --font-accent-red: #fff;
  --font-accent-green: #fff;

  --font-primary-dark: #ddd;
  --shadow-primary: #fafafa33;
  --bg-blur: rgba(0, 0, 0, 0.5);
}
.light-green {
  --bg-primary: #222831;
  --bg-secondary: #393e46;
  /* --bg-secondary: #603e56; */
  /* --bg-secondary: #2e6060; */
  --bg-accent: #00adb5;
  --bg-accent-secondary: #00adb5;

  --accent-red: #ff5252;
  --accent-green: #69db7c;

  --font-primary: #eeeeee;
  --font-secondary: #c2c2c2;
  /* --font-secondary: #c2c2c2bc; */

  --font-accent-red: #fff;
  --font-accent-green: #fff;

  --font-primary-dark: #ddd;
  --shadow-primary: rgba(255, 255, 255, 0.1);
  --bg-blur: rgba(0, 0, 0, 0.5);
}
.dark2 {
  --bg-primary: #1c1c1e;
  --bg-secondary: #2c2c2e;
  /* --bg-secondary: #603e56; */
  /* --bg-secondary: #2e6060; */
  --bg-accent: #ffadb0;
  --bg-accent-secondary: #00adba;

  --accent-red: #ff453a;
  --accent-green: #32d74b;

  --font-primary: #f2f2f7aa;
  --font-secondary: #bfbfcf;
  /* --font-secondary: #c2c2c2bc; */

  --font-accent-red: #fff;
  --font-accent-green: #fff;

  --font-primary-dark: #ddd;
  --shadow-primary: rgba(180, 180, 180, 0.2);
  --bg-blur: rgba(0, 0, 0, 0.5);
}
.dark3 {
  --bg-primary: #222;
  --bg-secondary: #1a1a1a;
  /* --bg-secondary: #603e56; */
  /* --bg-secondary: #2e6060; */
  --bg-accent: #66c2ba;
  --bg-accent-secondary: #6d4c41;

  --accent-red: #ff5252;
  --accent-green: #8bc34a;

  --font-primary: #f2f2f2;
  --font-primary: #aaa;
  --font-secondary: #b3b3b3;
  --font-secondary: #999999;
  /* --font-secondary: #c2c2c2bc; */

  --font-accent-red: #fff;
  --font-accent-green: #fff;

  --font-primary-dark: #ddd;
  --shadow-primary: rgba(180, 180, 180, 0.3);
  --bg-blur: rgba(0, 0, 0, 0.5);
}
/********************* Themes *************************/

/****** font color ****/

.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-blur);
  z-index: 99;
  transition: all 800ms;
}

.popup {
  position: absolute;
  background: var(--bg-primary);
  width: 50vw;
  padding: 1rem 2rem;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1;
  box-shadow: 2px 6px 6px var(--shadow-primary);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px var(--shadow-primary);
}

button {
  padding: 10px 20px;
  margin-block: 0.5rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  width: fit-content;
  margin-inline: auto;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}
