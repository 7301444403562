.tag-container {
  width: 40vmin;
  color: var(--font-primary);
  padding-block: 2rem;
}

.add-tag {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  flex-direction: column;
}

.add-tag > label > input {
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 17px;
  width: 100%;
}

.add-tag-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-tag-form > button {
  width: 100%;
  margin-block: 0.5rem;
}

.add-tag > label {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.tag-container > .tags span {
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
  position: relative;
  overflow: hidden;
  overflow-wrap: anywhere;
  font-size: 1rem;
}
.tag-container > .tags span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 70%);
  height: 100%;
  width: 100%;
  color: var(--accent-red);
  transition: transform 0.4s;
  border-radius: inherit;
  background-color: rgba(255, 200, 200, 0.55);
  font-size: 10px;
}

.cancel-icon > svg {
  color: var(--bg-accent);
}
@media screen and (max-width: 660px) {
  .tag-container {
    width: 50vmin;
  }
}
@media screen and (max-width: 550px) {
  .tag-container {
    width: 55vmin;
  }
}
@media screen and (max-width: 500px) {
  .tag-container {
    width: 60vmin;
  }
}
@media screen and (max-width: 450px) {
  .tag-container {
    width: 70vmin;
  }
}
@media screen and (max-width: 400px) {
  .tag-container {
    width: 80vmin;
  }
}

.dropdown {
  position: absolute;
  background: var(--bg-secondary);
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px var(--shadow-primary);
  border-radius: 5px;
  max-height: 130px;
  top: 2.4rem;
  font-size: 1rem;
  overflow-y: scroll;
  left: 3rem;
  right: 0;
}
.dropdown:empty {
  display: none;
}
.dropdown-row {
  margin: 5px;
  cursor: pointer;
}
.dropdown-row > .divider {
  width: 100%;
}
