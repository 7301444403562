.delete-popup-container {
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 24px;
  white-space: nowrap;
}

.popup-btn-group {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
