.card-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: max-content;
  min-height: 20vmin;
  justify-content: space-between;
}

.card {
  font-size: 20px;
  color: var(--font-secondary);
  background: var(--bg-secondary);
  box-shadow: 0px 0px 4px var(--shadow-primary);
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  height: min-content;
}

.card-container > div {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--font-primary);
}
.card-container > .charts > .card {
  width: 250px;
  height: 200px;
}
.card-container > .charts > .card:nth-child(2) {
  width: 350px;
}

.dashboard-container > button:nth-child(1) {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-block-end: 1rem;
  background: var(--accent-green);
  color: var(--font-accent-green);
  font-size: 21px;
  border-radius: 13px;
  box-shadow: 0 0 15px var(--shadow-primary);
  z-index: 10;
}
@media screen and (max-width: 440px) {
  .card-container > .charts > .card {
    width: 90%;
    width: 60%;
    height: 170px;
  }
  .card-container > .charts > .card:nth-child(2) {
    width: 70%;
    height: 170px;
  }
  .dashboard-container > button:nth-child(1) {
    font-size: 20px;
    padding: 8px;
  }
}

@media screen and (max-width: 380px) {
  .card-container > .charts > .card {
    height: 150px;
  }
  .card-container > .charts > .card:nth-child(2) {
    height: 150px;
  }
}
@media screen and (max-width: 340px) {
  .card-container > .charts > .card {
    height: 140px;
  }
  .card-container > .charts > .card:nth-child(2) {
    height: 130px;
  }
}
@media screen and (max-width: 310px) {
  .card-container > .charts > .card {
    height: 125px;
  }
  .card-container > .charts > .card:nth-child(2) {
    height: 120px;
  }
}
