.header {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 100%;
  padding: 0.3em;
}

.calender-head {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-around;
}

.notebook-page {
  width: 80%;
  margin: auto;
  padding: 2em;
  box-sizing: border-box;
  border: 1px solid #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.notebook-page {
  width: -webkit-fill-available;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8ff;
  border: 1px solid #999;
  box-shadow: 10px 10px 5px #888888;
}

.notebook-text {
  width: 100%;
  height: match-parent;
  resize: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
}
