.font-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--font-primary);
}
.fonts > select {
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: inherit;
  padding: 3px 5px;
  background: var(--bg-secondary);
  color: var(--font-secondary);
}
input[type='range'] {
  -webkit-appearance: none;
  width: 80%;
  height: 5px;
  background-color: var(--shadow-primary);
  border-radius: 10px;
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: var(--bg-accent);
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 300px) {
  .font-settings {
    flex-direction: column;
    margin-block-end: 1rem;
  }
  .font-settings-container {
    padding: 1rem;
  }
}
