.search-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
  gap: 1rem;
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.search-input {
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  font-size: 18px;
  overflow: hidden;
  width: 100%;
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  opacity: 0.5;
  color: var(--font-secondary);
  cursor: pointer;
  transition: left 0.2s ease-in-out, padding 0.5s ease-in-out;
}
.search-input:focus + .search-icon {
  left: -3rem;
}
.filter-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: 12px;
  top: 11px;
  background: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
  animation: inAnimation 200ms ease-in;
  /* box-shadow: 0px 0px 5px gray; */
  box-shadow: 0 0 10px var(--shadow-primary);
  color: var(--font-secondary);
  width: 150px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
}

.filter-container label {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px 5px;
}
.filter-container label span {
  white-space: nowrap;
  font-weight: bold;
  color: var(--font-secondary);
  padding-inline: 0.5rem;
}
.filter-icon {
  display: flex;
  align-items: center;
  color: var(--bg-accent);
  font-size: 20px;
  cursor: pointer;
}

.filter-container input[type='month'] {
  font-size: 20px;
  font-family: inherit;
  background: inherit;
  border: none;
  outline: none;
  color: var(--font-secondary);
  width: auto;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  outline: none;
}

.filter-container input[type='month']::-webkit-calendar-picker-indicator {
  margin: 0;
  padding: 0;
  border: none;
  display: block;
}
.filter-container input[type='month']::-moz-calendar-picker-indicator {
  margin: 0;
  padding: 0;
  border: none;
  display: block;
}

.filter-container select {
  padding: 0.5rem;
  border: none;
  font-size: inherit;
  outline: none;
  background: inherit;
  color: var(--font-secondary);
}

@media screen and (max-width: 350px) {
  .filter-container {
    width: 80vmin;
  }
}
