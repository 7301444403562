.register-container > form {
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  margin-block-start: 1rem;
  text-align: start;
}

.register-container > p {
  margin-block: 5px;
}
.register-container {
  text-align: center;
  color: var(--font-secondary);
}
.register-container > form input {
  padding: 10px;
  border-radius: 8px;
  margin-block: 8px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 18px;
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
  box-sizing: border-box;
}

.register-container > form > button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: var(--accent-green);
  color: var(--font-accent-green);
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0 1px 2px var(--shadow-primary);
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  width: fit-content;
  margin-inline: auto;
}

.register-container > form > div > span {
  top: 0px;
  left: 0;
}
.status-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  right: 0;
}

.input-hint {
  font-size: 13px;
  margin-block: 0 5px;
}

.register-fingerprint-container {
  text-align: center;
  color: var(--font-primary);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register-fingerprint-container > button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: var(--accent-green);
  color: var(--font-accent-green);
  cursor: pointer;
  box-shadow: 0 1px 2px var(--shadow-primary);
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.secondary-submit {
  color: var(--font-primary);
  text-align: center;
  font-size: 20px;
  text-decoration: underline 1px var(--font-primary);
  cursor: pointer;
}
