.template-selector-container > div {
  color: var(--font-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-selector-container > div > .menu-item-left {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.cancel-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.add-prompt-btn {
  margin-top: 1rem;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--accent-green);
  color: var(--font-accent-green);
}

.new-prompt-container {
  position: absolute;
  background-color: teal;
}

.template-selector-container label span {
  display: flex;
  align-items: center;
}

@media (max-width: 750px) {
  .popup {
    width: 60vw;
    padding-inline: 1.5rem;
  }
}
@media (max-width: 600px) {
  .popup {
    width: 70vw;
    /* padding-inline: 1rem; */
  }
}
@media (max-width: 550px) {
  .popup {
    width: 75vw;
    /* padding-inline: 10px; */
  }
}
@media (max-width: 500px) {
  .menu-item-left > svg {
    display: none;
  }
}
