/* .main-content {
  font-family: 'Kalam', cursive;
  text-align: center;
} */

.journal-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--color-primary);
}
.journal-container > textarea {
  height: 100%;
  padding: 1rem;
  padding-inline: 5rem;
  line-height: 1.5em;
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  text-align: start;
  overflow: scroll;
  width: -webkit-fill-available;
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
}

.top-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  padding-block: 10px;
  background: var(--bg-primary);
  color: var(--bg-accent);
}

.left-header,
.right-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.date-selector {
  padding: 10px;
  background-color: var(--bg-secondary);
}

.date-selector input {
  font-size: 20px;
  font-family: inherit;
  background: inherit;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--font-secondary);
  cursor: pointer;
  color: var(--font-secondary);
}

.date-selector input[type='date']::-webkit-calendar-picker-indicator {
  margin: 0;
  padding: 0;
  transform: translateX(-25px);
}

head {
  font-size: 18px;
  border: none;
  padding: 10px 1rem;
  outline: none;
}

.divider {
  width: 100%;
  margin: 0;
  background: var(--shadow-light);
}

.menu-container {
  position: absolute;
  right: 5px;
  top: 15px;
  height: max-content;
  width: 200px;
  background: var(--color-bg-light);
  border-radius: 10px;
  animation: inAnimation 200ms ease-in;
  z-index: 99;
  box-shadow: 0px 2px 4px var(--shadow-primary);
  background: var(--bg-secondary);
  color: var(--font-secondary);
}
.menu-container > .menu-item > svg {
  color: var(--bg-accent-secondary);
}

.bottom-menu {
  display: flex;
  justify-content: center;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  z-index: 1;
}
.tag {
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  padding: 2px 5px;
  border-radius: 15%;
  gap: 3px;
  color: var(--font-primary);
  box-shadow: 0px 0px 4px var(--shadow-primary-dark);
}
.tag > svg {
  color: var(--bg-accent-secondary);
  font-size: 12px;
}
@media (max-width: 800px) {
  .journal-container > textarea {
    padding-inline: 4rem;
  }
}
@media (max-width: 700px) {
  .journal-container > textarea {
    padding-inline: 3rem;
  }
}
@media (max-width: 600px) {
  .journal-container > textarea {
    padding-inline: 2rem;
  }
}
@media (max-width: 500px) {
  .journal-container > textarea {
    padding-inline: 1rem;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
