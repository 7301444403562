.set-password-container {
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px var(--shadow-primary);
  background: var(--bg-primary);
  color: var(--font-primary);
}

.set-password-container > h2 {
  text-align: center;
}

.password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.set-password-container .title {
  margin-block: 0 1rem;
}
.password-form input {
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  outline: none;
  border: none;
  background-color: var(--bg-secondary);
  color: var(--font-secondary);
  font-size: 22px;
  text-align: center;
  letter-spacing: 5px;
  font-family: 'Squared';
}

.password-form input::placeholder {
  letter-spacing: 0px;
}

@media (max-width: 800px) {
  .set-password-container {
    width: 35vmin;
  }
}
@media (max-width: 700px) {
  .set-password-container {
    width: 40vmin;
  }
}
@media (max-width: 600px) {
  .set-password-container {
    width: 45vmin;
  }
}
@media (max-width: 550px) {
  .set-password-container {
    width: 70vmin;
  }
}

.btn-grp-2 {
  display: flex;
  justify-content: space-between;
}

.error-message {
  position: absolute;
  font-size: 17px;
  color: var(--accent-red);
  width: 100%;

  top: -15px;
  left: 0;
  text-align: start;
  white-space: nowrap;
  font-weight: 500;
}
