.toolbar {
  margin: auto;
  position: sticky;
  z-index: 10;
}

.editor {
  height: 100%;
  padding: 1rem;
  padding-inline: 1.5rem;
  line-height: 1.5em;
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  text-align: start;
  overflow: scroll;
  width: -webkit-fill-available;
  /* background-color: var(--bg-secondary); */
  background-color: #fff;
  color: var(--font-secondary);
}
